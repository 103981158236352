import React from "react";
import GeoComplyMapPin from "./GeoComplyMapPin";
import GeoComplyMapError from "./GeoComplyMapError";
import ErrorPage from "./ErrorPage";

export type Error = "pin" | "error" | "loginError";
interface Props {
  error?: Error;
}

const ErrorPageImgMap = ({ error }: Props) => {
  switch (error) {
    case "pin":
      return <GeoComplyMapPin />;
    case "error":
      return <GeoComplyMapError />;
    case "loginError":
      return <ErrorPage />;
    default:
      return <GeoComplyMapPin />;
  }
};

export default ErrorPageImgMap;
