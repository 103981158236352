import styled, { css } from "styled-components";
import { ErrorDescriptionEnum } from "@tvg/ts-types/GeoComply";

export const DescriptionWrapper = styled.div`
  margin-top: 32px;
`;

export const DescriptionContent = styled.ul<{
  type: ErrorDescriptionEnum;
}>`
  margin: 12px 0;
  ${({ type }) =>
    (type === ErrorDescriptionEnum.TIPS ||
      type === ErrorDescriptionEnum.STEPS) &&
    css`
      list-style-position: inside;
      list-style-type: ${type === ErrorDescriptionEnum.TIPS ? "none" : "auto"};
    `}
`;

export const DescriptionItem = styled.li<{
  isListItem?: boolean;
}>`
  display: ${({ isListItem }) => (isListItem ? "list-item" : "flex")};
  color: ${({ theme }) => theme.colorTokens.content.onDark};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: 17px;
  font-weight: 400;

  &:only-child {
    line-height: 22px;
  }

  &:first-child {
    margin-bottom: 32px;
  }

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  & > svg {
    width: 100%;
    max-width: 18px;
    margin-right: 8px;
  }
`;
