import { NullaryFn, UnaryFn } from "@tvg/ts-types/Functional";

const getGeolocationPosition = (
  successCallback: NullaryFn<void>,
  errorCallback: NullaryFn<void>,
  options = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 300000
  }
) => {
  navigator.geolocation.getCurrentPosition(
    successCallback,
    errorCallback,
    options
  );
};

export const checkLocationPermission = () =>
  new Promise((resolve: UnaryFn<boolean, void>) => {
    const navigatorCompatible =
      window && window.navigator && window.navigator.geolocation;
    // Safari only will support permission.query in the future, on the version 16.
    const navigatorQuerySupported =
      navigatorCompatible && typeof navigator.permissions !== "undefined";

    const successCallback = () => {
      resolve(true);
    };

    const errorCallback = () => {
      resolve(false);
    };

    if (!navigatorCompatible) {
      errorCallback();
      return;
    }

    if (navigatorQuerySupported) {
      navigator.permissions
        .query({
          name: "geolocation"
        })
        .then(({ state: permissionState }) => {
          if (permissionState === "prompt") {
            getGeolocationPosition(successCallback, errorCallback);
          } else {
            resolve(permissionState === "granted");
          }
        });
    } else {
      getGeolocationPosition(successCallback, errorCallback);
    }
  });
