/* eslint no-underscore-dangle: ["error", { "allow": ["_sift"] }] */

import React, { useEffect, useRef, useState } from "react";
import type { History } from "history";
import { useNavigate as useHistory } from "@tvg/custom-hooks";
import { GeoToast } from "@tvg/design-system/web";
import { geolocationStatusClear } from "@tvg/sh-geolocation/src/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  getGeoComplyRetryStatus,
  getGeolocationMessageShown,
  getGeolocationStatus
} from "@tvg/sh-geolocation/src/redux/selectors";
import { parseJSONCapiMessage } from "@fdr/utils/parseJSONCapiMessage";
import { attempt, get, noop } from "lodash";
import LocationSplash from "@urp/location-splash";
import mediator from "@tvg/mediator";
import { useLocation } from "react-router-dom";
import { StartGeoComply } from "@tvg/sh-geolocation/src/components/startGeoComply";
import { isTvg5 } from "@tvg/utils/generalUtils";
import StateSelectorModal from "@urp/comp-state-selector/src/stateSelectorModal";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import interceptRequests from "@tvg/perimeterx/src";
// @ts-ignore
import { geoComplySubscriptions } from "@tvg/sh-geolocation/src/utils/subscriptions";
// @ts-ignore
import Poller from "@tvg/poller";
import {
  getAuthTokenCookie,
  getUserSessionData,
  getUserSessionStartDate,
  isAccountCompliantSelector
} from "@tvg/sh-utils/sessionUtils";
import { sessionStartUpdate } from "@tvg/shared-actions/UserActions";
import { GeolocationRetryState } from "@tvg/sh-geolocation/src/types";
import {
  globalCallbackSubscriptions,
  triggerGlobalCallback
} from "@tvg/sh-utils/callbackUtils";
import {
  manageEquibaseId,
  promosSubscriber
} from "@tvg/sh-lib-account-actions/src/utils/controllerLogic";
import fetchUserFavorites, {
  updateFavoriteTrack,
  updateUserFavoriteTracks
} from "@tvg/sh-lib-account-actions/src/utils/userFavorites";
import getUserPreferences from "@tvg/sh-lib-account-actions/src/utils/userPreferences";
import { setUserData } from "@fdr/shared-actions/UserActions";
import CPPService from "@tvg/sh-cpp";
import { storeCPPPromotions } from "@tvg/sh-cpp/src/service/redux/actions";
import { getSession } from "@urp/unified-modules/src/utils/account";

const PromosPoller = new Poller();
const cppService = new CPPService();

const classicLoginHandler = (data: { callback: () => void }) => {
  getSession("Normal", data.callback || noop);
};

const getGeoComplyToastOffset = (isBetSlipOpen: boolean) => {
  if (typeof window !== "undefined" && isBetSlipOpen) {
    const element = document.getElementById("bet-slip-wrapper");

    if (element) {
      const style = getComputedStyle(element);
      const paddingY =
        parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);

      return element.clientHeight - paddingY;
    }
  }

  return 0;
};

const LoginController = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory() as unknown as History;
  const geolocationStatus = useSelector(getGeolocationStatus);
  const geoLocationToastMsg = useSelector((store) =>
    parseJSONCapiMessage(store, "capi.messages.geoLocationToastMsg", {})
  );
  const geolocationMessageShown = useSelector(getGeolocationMessageShown);
  const geoComplyRetryStatus = useSelector(getGeoComplyRetryStatus);

  const isBetSlipOpen = useSelector((store) =>
    get(store, "BetTicket.betSlipOpen", false)
  );
  const isAccountCompliant = useSelector(isAccountCompliantSelector);
  const twoFactorAuthStates = useSelector((store) =>
    parseJSONCapiMessage(store, "capi.messages.twoFactorAuthStates", [])
  );
  const overwritePreferences = useSelector((store) =>
    parseJSONCapiMessage(store, "capi.messages.overwritePreferences", {})
  );
  const allowedStates = useSelector((store) =>
    Object.keys(
      parseJSONCapiMessage(store, "capi.messages.stateSelectorListFdr", {})
    )
  );
  const enableSift = useSelector((store) =>
    get(store, "capi.featureToggles.enableSift", false)
  );
  const geolocationGPSInvalidMessage = useSelector((store) =>
    parseJSONCapiMessage(
      store,
      "capi.messages.geoLocationGPStoreStateInvalid",
      {}
    )
  );

  const geolocation = useSelector((store) => get(store, "geolocation"));
  const userData = useSelector((store) => get(store, "userData", {}));
  const enableCPPPromos = useSelector((store) =>
    get(store, "capi.featureToggles.enableCPPPromos", false)
  );
  const [isGeoComplyListening, setIsGeoComplyListening] = useState(false);
  const [siftInitialised, setSiftInitialised] = useState(false);
  const functionsToSubscribe = useRef({
    handlePromosSubscribe: noop
  });
  const currentPathWithParams = location.pathname + location.search;

  const handlePromosSubscribe = () => {
    promosSubscriber({
      isLogged: userData.logged,
      accountNumber: userData.user?.accountNumber,
      loadingPromos: userData.loadingPromos,
      dispatch
    });
  };

  const pushSiftWindowVariables = () => {
    const tokenUserData = getUserSessionData(getAuthTokenCookie());
    if (
      typeof window !== "undefined" &&
      tokenUserData.fdUserId &&
      // @ts-ignore
      window._sift[1] !== ["_setUserId", `${tokenUserData.fdUserId}`] &&
      enableSift &&
      userData?.logged &&
      !siftInitialised
    ) {
      // @ts-ignore
      window._sift.push(["_setUserId", `${tokenUserData.fdUserId}`]);
      // @ts-ignore
      window._sift.push(["_setSessionId", `${tokenUserData.sessionId || ""}`]);
      // @ts-ignore
      window._sift.push(["_trackPageview"]);
      setSiftInitialised(true);
    }
  };

  functionsToSubscribe.current = {
    handlePromosSubscribe
  };

  useEffect(() => {
    mediator.base.subscribe(
      "UPDATE_SESSION_FAVORITE_TRACKS",
      updateFavoriteTrack(dispatch)
    );

    mediatorClassic.subscribe(
      "NEW_FAVORITE_TRACKS",
      updateUserFavoriteTracks(dispatch)
    );

    mediator.base.subscribe("TVG_LOGIN:GET_USER_PROMOS", () =>
      functionsToSubscribe.current.handlePromosSubscribe()
    );

    mediatorClassic.subscribe(
      "TVG_LOGIN:OPEN_LOGIN_MODAL",
      classicLoginHandler
    );

    if (!userData?.logged) {
      setTimeout(() => {
        dispatch(
          setUserData({
            hasRequested: true
          })
        );
      });
    }
    // get 403 requests from perimeterx
    interceptRequests();

    globalCallbackSubscriptions();

    return () => {
      mediator.base.unsubscribe(
        "TVG_LOGIN:GET_USER_PROMOS",
        handlePromosSubscribe
      );

      mediator.base.unsubscribe(
        "TVG_LOGIN:OPEN_LOGIN_MODAL",
        classicLoginHandler
      );
    };
  }, []);

  useEffect(() => {
    if (!isGeoComplyListening && twoFactorAuthStates && allowedStates) {
      setIsGeoComplyListening(true);
      geoComplySubscriptions(
        dispatch,
        {
          twoFactorAuthStates,
          wageringStates: allowedStates
        },
        geolocationGPSInvalidMessage
      );
    }
  }, [isGeoComplyListening, twoFactorAuthStates, allowedStates]);

  useEffect(() => {
    if (
      userData?.logged &&
      userData.user?.accountNumber &&
      userData.user.accountNumber !== "" &&
      !userData.gotPromos &&
      !userData.loadingPromos
    ) {
      mediator.base.dispatch({
        type: "TVG_LOGIN:GET_USER_PROMOS"
      });

      if (!PromosPoller.isRunning() && isTvg5()) {
        PromosPoller.start(
          () =>
            mediator.base.dispatch({
              type: "TVG_LOGIN:GET_USER_PROMOS"
            }),
          60000
        );
      }
    }

    if (getAuthTokenCookie() && !siftInitialised) {
      pushSiftWindowVariables();
    }

    if (!userData.logged && userData.gotPromos) {
      mediator.base.dispatch({
        type: "TVG_LOGIN:CLEAR_USER_PROMOS"
      });
    }
  }, [JSON.stringify(userData)]);

  useEffect(() => {
    if (userData?.logged) {
      if (!userData?.sessionStartAt) {
        dispatch(
          sessionStartUpdate(getUserSessionStartDate(getAuthTokenCookie()))
        );
      }

      getUserPreferences(
        userData.user?.accountNumber,
        dispatch,
        overwritePreferences
      );
      if (isAccountCompliant && userData.user?.accountNumber) {
        fetchUserFavorites(userData.user.accountNumber, dispatch);
      }
      attempt(() => window.localStorage.setItem("userLoginOnce", "true"));
      manageEquibaseId(true);
    }
  }, [userData?.logged, JSON.stringify(overwritePreferences)]);

  useEffect(() => {
    if (userData?.logged !== undefined && enableCPPPromos) {
      cppService.getPromos().then((cppPromos) =>
        // @ts-ignore
        dispatch(storeCPPPromotions(cppPromos || []))
      );
    }
  }, [userData?.logged]);

  return (
    <>
      {geolocationStatus &&
        geoLocationToastMsg &&
        geoLocationToastMsg[geolocationStatus] && (
          <GeoToast
            {...geoLocationToastMsg[geolocationStatus]}
            noAnimation={geolocationMessageShown}
            callback={() => dispatch(geolocationStatusClear())}
            offset={getGeoComplyToastOffset(isBetSlipOpen)}
          />
        )}
      <LocationSplash history={history} />
      <StateSelectorModal
        currentPath={currentPathWithParams}
        onClose={() => {
          triggerGlobalCallback();
        }}
      />
      <StartGeoComply
        geoState={get(geolocation, "state")}
        isLogged={userData?.logged}
        geoComplyRetryStatus={
          geoComplyRetryStatus as unknown as GeolocationRetryState
        }
      />
    </>
  );
};

export default LoginController;
