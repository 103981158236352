import styled from "styled-components";
// @ts-ignore
import fanduelShield from "./SvgImages/assets/fanduel-logo.png";

export const PageErrorWrapper = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("${fanduelShield}");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 60vw 32px;
    opacity: 0.5;
  }
`;

export const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  position: relative;
`;

export const MapImage = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

export const ActionsContent = styled.div<{ hasDescription?: boolean }>`
  padding-top: 32px;
`;

export const HeaderContent = styled.div`
  text-align: center;
`;
